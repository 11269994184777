<template>
  <section
    id="get-in-touch"
    class="overflow-hidden"
  >
    <v-row
      class="accent info--text"
      no-gutters
      
    >
    <v-col cols="12" class="text-center">
        <div class="mx-auto">
        <base-heading class="mb-2 ml-2 pt-4">
          Kontaktieren Sie uns:
        </base-heading>
      </div>    
    </v-col>

      <v-col
        class="px-4 pb-4"
        cols="10"
        md="8"
        offset="1"
        offset-md="2"
      >
        

        

        <v-form ref="form">   
          <v-row no-gutters>
            <v-col class="py-0 px-2" cols="12" sm="6">
               <v-text-field
            label="Vorname*"
            dense solo flat
            v-model="Vorname"
            :rules="inputRules"
            required
            id="vorname"
          />
            </v-col>
            <v-col class="py-0 px-2" cols="12" sm="6">
              <v-text-field
            label="Nachname*"
            dense solo flat
            v-model="Nachname"
            :rules="inputRules"
            required
            id="nachname"
          />
          </v-col>
          <v-col class="py-0 px-2" cols="12" sm="6">
               <v-text-field
            label="Telefonnummer*"
            dense solo flat
            v-model="Telefonnummer"
            :rules="inputRules"
            required
            id="telefonnummer"
          />
            </v-col>
            <v-col class="py-0 px-2" cols="12" sm="6">
              <v-text-field
            label="Firmenname*"
            dense solo flat
            v-model="Firmenname"
            :rules="inputRules"
            required
            id="firmenname"
          />
            </v-col>
            <v-col class="py-0 px-2" cols="12" >
               <v-text-field
            label="Email*"
            dense solo flat
            v-model="Email"
            :rules="emailRules"
            required
            id="email"
          />
            </v-col>
            
            <v-col class="py-0 px-2" cols="12">
              <v-textarea
            label="Nachricht*"
            solo flat
            v-model="Nachricht"
            :rules="inputRules"
            required
            id="nachricht"
          />
            </v-col>
          </v-row>
          <v-row class="pa-0" no-gutters>
            <div class="mx-auto">
              <div class="white--text pb-2 ml-2 info--text">mit * gekennzeichnete Felder sind Pflichtfelder</div>
            </div>
          </v-row>
          <v-row class="pa-0" no-gutters>
            <div class="mx-auto">
              <v-btn @click="submit" class="ml-2" id="abschicken">Abschicken</v-btn>
            </div>
          </v-row>
          
        </v-form>
        
      </v-col>

<MsgSnackbar/>

    </v-row>
  </section>
</template>

<script>
import db from '@/fb'
import MsgSnackbar from '@/components/MsgSnackbar'

  export default {
    name: 'GetInTouch',
    components:{
      MsgSnackbar
    },
    data() {
      return {
        Firmenname: '',
        Nachname: '',
        Vorname: '',
        Email: '',
        Telefonnummer: '',
        Nachricht: '',
        inputRules: [
          v => !!v || 'Dies ist ein Pflichtfeld',
        ],
        emailRules: [
        v => !!v || 'Dies ist ein Pflichtfeld',
        v => /.+@.+/.test(v) || 'Die E-Mail muss gültig sein',
      ],
      }
    },
    computed: {
      to(){
        return this.$store.getters['getInTouch_cms/getMailRecepients']
      }
    },
    methods: {
      submit() {

        if(this.$refs.form.validate()){
          const contact = {
          Firmenname: this.Firmenname,
          Nachname: this.Nachname,
          Vorname: this.Vorname,
          Email: this.Email,
          Telefonnummer: this.Telefonnummer,
          Nachricht: this.Nachricht
        }
        db.collection('contacts').add(contact).then(() => {
          this.$refs.form.reset()
        })
        db.collection('mail').add({
          to: this.to,
          message: {
            subject: 'Kontaktanfrage von '+this.Email+' über die Seite "Slenderiser Kalender" (https://contact-us-database-45c74.web.app/)',
            html: '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><title>Slenderiser Kontaktformular</title><meta name="viewport" content="width=device-width, initial-scale=1.0"/></head></html>'+'<body style="margin: 0; padding: 0;"> <div>Hallo,</div><div>'+this.Vorname+' '+this.Nachname+' hat folgende Nachricht:</div><div><br></div><div>'+this.Nachricht+' </div><div><br></div><div>Kontaktdaten:</div><div>'+this.Vorname+' '+this.Nachname+'</div><div> Unternehmen: '+this.Firmenname+'</div><div> E-Mail: '+this.Email+'</div><div> Telefon: '+this.Telefonnummer+'</div> </body>'
          }
        }).then(() =>
          this.$store.dispatch('getInTouch_cms/showBoolean',{text:'Kontaktdaten wurden erfolgreich gesendet',color:'success'}) ,
          db.collection('mail').add({
             to: [this.Email],
          message: {
            subject: 'Versandbestätigung',
            html: '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><title>Slenderiser Kontaktformular</title><meta name="viewport" content="width=device-width, initial-scale=1.0"/></head></html>'+'<body style="margin: 0; padding: 0;"> <div>Hallo '+this.Vorname+' '+this.Nachname+', Sie haben und folgende Informationen übermittelt:</div><div><br></div><div>'+this.Nachricht+' </div><div><br></div><div>Kontaktdaten:</div><div>'+this.Vorname+' '+this.Nachname+'</div><div> Unternehmen: '+this.Firmenname+'</div><div> E-Mail: '+this.Email+'</div><div> Telefon: '+this.Telefonnummer+'</div><div><br></div><div>Bitte antworten Sie nicht auf diese E-mail. Wir werden uns bei Ihnen melden.</div> </body>'
          }
          })
          .catch( (error) => 
        this.$store.dispatch('getInTouch_cms/showBoolean',{text:error,color:'red darken-1'})
        )
        )
         .catch( (error) => 
        this.$store.dispatch('getInTouch_cms/showBoolean',{text:error,color:'red darken-1'})
        )
        }
        else{
          setTimeout(() => {
            this.$refs.form.resetValidation()
          }, 3000);        
        }

        
      }
    }
  }

</script>

